import React, { Component } from 'react';
import { connect } from 'react-redux';
import PortfolioSideNav from './PortfolioSideNav';
import { withRouter } from 'react-router';
import { sideNavItemSelected } from '../../actionCreators';
import { loadedApplication } from '../../actionCreators';
import { bindActionCreators } from 'redux';










///OLD FUNCTIONAL COMPONENT THAT DOESN'T WORK!!




class PortfolioSideNavConnector extends Component {
  componentDidMount() {
    window.onpopstate = () => this.forceUpdate();
  }
  render = () => {
    const { history, selectedPortfolioSideNavItem, sideNavItems, projectData } = this.props;
    return (
      <PortfolioSideNav
        sideNavItemSelected={sideNavItemSelected}
        history={history}
        selectedPortfolioSideNavItem={selectedPortfolioSideNavItem}
        sideNavItems={sideNavItems}
        projectData={projectData}
        loadedApplication={loadedApplication}
      />
    );
  };
}


const mapDispatchToProps = (dispatch) => ({ 
  ...bindActionCreators({loadedApplication}, dispatch) 
});

//this is from the reducer index file
const mapStateToProps = ({portfolioSideNav: { selectedPortfolioSideNavItem, sideNavItems }, app:{projectData}},state) => {

  return({ selectedPortfolioSideNavItem, sideNavItems, projectData})

};
// const mapStateToProps = ({portfolioSideNav: { selectedPortfolioSideNavItem, sideNavItems }}, {app: { projectData}}) => ({ selectedPortfolioSideNavItem, sideNavItems, projectData });

//withrouter adds history object
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioSideNavConnector));

// const mapStateToProps = ({portfolioSideNav: { selectedPortfolioSideNavItem, sideNavItems, benTest }},{modal: {modalOpen}},state) => {

//   console.log(state, 'hello?')
//   return({ selectedPortfolioSideNavItem, sideNavItems, benTest, modalOpen})

// };


