import React, { Component } from 'react';
import { awaProjects } from '../../constants/appData/projects';
import ScrollCarouselProjectContent from './ScrollCarouselProjectContent';



export default class ScrollCarouselProject extends Component {
  state = {
    loaded: false,
    scrollProjectIndex: 0,
    projects: [{fields:{
                name: '',
                project_images: ['imageURL'],
                info_text: '',
                displayName: ''
                }
              }]
  };
  projectContainerRef = React.createRef();

  scrollListener = () => {
    const { sideNavItemSelected, history, sideNavItems: projects, projectData } = this.props;
    const { scrollProjectIndex } = this.state;
    const firstProject = this.projectContainerRef.current.children[1];
    const style = firstProject.currentStyle || window.getComputedStyle(firstProject);
    const projectHeight = firstProject.scrollHeight; //header is 90...BENDO: get header height dynamically..no magic numbers!
    const calculateFirstProject = Math.floor(window.scrollY / (projectHeight + 90)); //firstProject.scrollHeight = 580 (header is 90...) = 670
    const calculateOtherProjects = Math.floor((window.scrollY - (projectHeight + 90)) / (projectHeight + parseInt(style.marginBottom, 10))); //zero out first project..then add (580 + 250(margin aka parseInt(style.marginBottom, 10)) = 830)

    const calculatedProjectIndex = ()=>{
      if (window.scrollY === 0){
        return 0;
      }
      if (window.scrollY > 0 && calculateFirstProject < 1){
        return 1;
      }
      if (calculateFirstProject >= 1) {
        return calculateOtherProjects + 2;
      }
    }

    const urlIndex = calculatedProjectIndex()
    const nextUrl = `/portfolio/${projects[urlIndex].redirectURL}`; 

    if (calculatedProjectIndex() !== scrollProjectIndex) {
      this.setState({ scrollProjectIndex: calculatedProjectIndex() });
      sideNavItemSelected(projects[calculatedProjectIndex()]);
      history.replace(nextUrl);
    }
      
  }
  
  componentDidMount() {
    // window.addEventListener('scroll', this.scrollListener, false); //removing this takes away the reset project on scroll from view!
    console.log(this.props, 'portfolio props');
    this.props.loadedApplication();
   

    if (this.props.route) {
      setTimeout(() => {
        this.setState({ loaded: true });
        document.getElementById(this.props.route) &&
          document
            .getElementById(this.props.route)
            .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener, false);
  }


  render() {
  return(
    <div>
    {/* <div onClick={()=> this.props.loadedApplication()}>hello, {this.props.projectData[0].fields.displayName.toString()}</div> */}
      <div
        ref={this.projectContainerRef}
        className="scroll-carousel-project-container"
      >
        {this.state.loaded === false ? <div id="spinner" /> : null}
        {this.props.projectData.map((project, index) =>
          (project.fields.displayName && project.fields.displayName !== 'Home') ? (
            <ScrollCarouselProjectContent
              key={index}
              project={project}
              index={index}
            />
          ) : null
        )}
      </div>
    </div>
    
  )};

 

};

          


