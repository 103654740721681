import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomeView from './HomeView';
import { loadedApplication } from '../../actionCreators';
import { bindActionCreators } from 'redux';

function select(state) {
  return {
      appData: state.app,
  };
}

class HomeViewContainer extends Component {
  render() {
      const { dispatch } = this.props;
      return (
              <HomeView
                  {...bindActionCreators({ loadedApplication }, dispatch)}
                  {...this.props.appData}
              />
      );
  }
}

export default connect(select)(HomeViewContainer);
