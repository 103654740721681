import createReducer from '../utils/createReducer';
import { APPLICATION_LOADED } from '../constants/actions';

const initialState = {
  isLoading: 'loading',
  projectData: [{fields:{displayName: 'home', name: 'HOME', project_images: [] }}]
};

export default createReducer(initialState, {
  [APPLICATION_LOADED]: (state, action) => {

    console.log(action.data.records, 'this is action data')
    // let apiProjects = [{fields:{displayName: 'home', name: 'HOME', project_images: [] }}];
    // action.data.data.forEach(project =>{
    //   apiProjects.push(project)
    // })

    return({
      ...state,
      projectData: action.data.records,
      isLoading: 'loaded',
    })
    
  },
});
