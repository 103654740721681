//////APP///////
export { default as favicon } from './app/favicon.ico';
export { default as awalogo } from './app/AWA-logo-2017.png';
export { default as aboutimage } from './app/aboutimage.jpg';
export { default as contactimage } from './app/contact.jpg';
export { default as contact_sketch } from './app/contact_sketch.jpg';

export { default as mainimage1 } from './app/mainimage1.jpg';
export { default as mainimage2 } from './app/mainimage2.jpg';
export { default as mainimage3 } from './app/mainimage3.jpg';
export { default as mainimage4 } from './app/mainimage4.jpg';
export { default as mainimage5 } from './app/mainimage5.jpg';
export { default as mainimage6 } from './app/mainimage6.jpg';
export { default as mainimage7 } from './app/mainimage7.jpg';

export { default as process1 } from './app/process_1.jpg';
export { default as process2 } from './app/process_2.jpg';
export { default as process3 } from './app/process_3.jpg';


////////// LA CUESTA //////////
export { default as la_cuesta_1 } from './la_cuesta/la_cuesta_1.jpg';
export { default as la_cuesta_2 } from './la_cuesta/la_cuesta_2.jpg';
export { default as la_cuesta_3 } from './la_cuesta/la_cuesta_3.jpg';
export { default as la_cuesta_4 } from './la_cuesta/la_cuesta_4.jpg';
export { default as la_cuesta_5 } from './la_cuesta/la_cuesta_5.jpg';
export { default as la_cuesta_6 } from './la_cuesta/la_cuesta_6.jpg';
export { default as la_cuesta_7 } from './la_cuesta/la_cuesta_7.jpg';
export { default as la_cuesta_8 } from './la_cuesta/la_cuesta_8.jpg';

////////// LA CUESTA 2 //////////
export { default as la_cuesta_2_1 } from './la_cuesta_2/la_cuesta_2_11.jpg';
export { default as la_cuesta_2_2 } from './la_cuesta_2/la_cuesta_2_6.jpg';
export { default as la_cuesta_2_3 } from './la_cuesta_2/la_cuesta_2_2.jpg';
export { default as la_cuesta_2_4 } from './la_cuesta_2/la_cuesta_2_7.jpg';
export { default as la_cuesta_2_5 } from './la_cuesta_2/la_cuesta_2_8.jpg';
export { default as la_cuesta_2_6 } from './la_cuesta_2/la_cuesta_2_12.jpg';


///////////DALE////////
export { default as dale_1 } from './dale/dale_1.jpg';
export { default as dale_2 } from './dale/dale_2.jpg';
export { default as dale_3 } from './dale/dale_3.jpg';
export { default as dale_4 } from './dale/dale_4.jpg';
export { default as dale_5 } from './dale/dale_5.jpg';
export { default as dale_6 } from './dale/dale_6.jpg';
export { default as dale_7 } from './dale/dale_7.jpg';
export { default as dale_8 } from './dale/dale_8.jpg';
export { default as dale_9 } from './dale/dale_9.jpg';
export { default as dale_10 } from './dale/dale_10.jpg';

///////////BALSAMINA////////
export { default as balsamina_1 } from './balsamina/balsamina_1.jpg';
export { default as balsamina_2 } from './balsamina/balsamina_2.jpg';
export { default as balsamina_3 } from './balsamina/balsamina_3.jpg';
export { default as balsamina_4 } from './balsamina/balsamina_4.jpg';
export { default as balsamina_5 } from './balsamina/balsamina_5.jpg';
export { default as balsamina_6 } from './balsamina/balsamina_6.jpg';
export { default as balsamina_7 } from './balsamina/balsamina_7.jpg';
export { default as balsamina_8 } from './balsamina/balsamina_8.jpg';
export { default as balsamina_9 } from './balsamina/balsamina_9.jpg';

///////////BRIGHT////////
export { default as bright_1 } from './bright/bright_1.jpg';
export { default as bright_2 } from './bright/bright_2.jpg';
export { default as bright_3 } from './bright/bright_3.jpg';
export { default as bright_4 } from './bright/bright_4.jpg';
export { default as bright_5 } from './bright/bright_5.jpg';
export { default as bright_6 } from './bright/bright_6.jpg';

///////////DETAIL////////
export { default as detail_1 } from './detail/detail_1.jpg';
export { default as detail_2 } from './detail/detail_2.jpg';
export { default as detail_3 } from './detail/detail_3.jpg';
export { default as detail_4 } from './detail/detail_4.jpg';
export { default as detail_5 } from './detail/detail_5.jpg';
export { default as detail_6 } from './detail/detail_6.jpg';
export { default as detail_7 } from './detail/detail_7.jpg';
export { default as detail_8 } from './detail/detail_8.jpg';

///////////BEAR GULCH////////
export { default as bear_gulch_1 } from './bear_gulch/bear_gulch_1.jpg';
export { default as bear_gulch_2 } from './bear_gulch/bear_gulch_2.jpg';
export { default as bear_gulch_3 } from './bear_gulch/bear_gulch_3.jpg';
export { default as bear_gulch_4 } from './bear_gulch/bear_gulch_4.jpg';
export { default as bear_gulch_5 } from './bear_gulch/bear_gulch_5.jpg';
export { default as bear_gulch_6 } from './bear_gulch/bear_gulch_6.jpg';
export { default as bear_gulch_7 } from './bear_gulch/bear_gulch_7.jpg';

//// CONTINENTAL ////
export { default as continental_1 } from './continental/continental_1.jpg';
export { default as continental_2 } from './continental/continental_2.jpg';
export { default as continental_3 } from './continental/continental_3.jpg';
export { default as continental_4 } from './continental/continental_4.jpg';
export { default as continental_5 } from './continental/continental_5.jpg';
export { default as continental_6 } from './continental/continental_6.jpg';
export { default as continental_7 } from './continental/continental_7.jpg';
export { default as continental_8 } from './continental/continental_8.jpg';

//// BARBARA ///////
export { default as barbara_1 } from './barbara/barbara_1.jpg';
export { default as barbara_2 } from './barbara/barbara_2.jpg';
export { default as barbara_3 } from './barbara/barbara_3.jpg';
export { default as barbara_4 } from './barbara/barbara_4.jpg';
export { default as barbara_5 } from './barbara/barbara_5.jpg';
export { default as barbara_6 } from './barbara/barbara_6.jpg';
export { default as barbara_7 } from './barbara/barbara_7.jpg';
export { default as barbara_8 } from './barbara/barbara_8.jpg';
export { default as barbara_9 } from './barbara/barbara_9.jpg';
export { default as barbara_10 } from './barbara/barbara_10.jpg';
export { default as barbara_11 } from './barbara/barbara_11.jpg';

/// PATTON ////
export { default as patton_1 } from './patton/patton_1.jpg';
export { default as patton_2 } from './patton/patton_2.jpg';
export { default as patton_3 } from './patton/patton_3.jpg';
export { default as patton_4 } from './patton/patton_4.jpg';
export { default as patton_5 } from './patton/patton_5.jpg';
export { default as patton_6 } from './patton/patton_6.jpg';
export { default as patton_7 } from './patton/patton_7.jpg';
export { default as patton_8 } from './patton/patton_8.jpg';
export { default as patton_9 } from './patton/patton_9.jpg';
export { default as patton_10 } from './patton/patton_10.jpg';

/// ERICA /////
export { default as erica_1 } from './erica/erica_1.jpg';
export { default as erica_2 } from './erica/erica_2.jpg';
export { default as erica_3 } from './erica/erica_3.jpg';
export { default as erica_4 } from './erica/erica_4.jpg';
export { default as erica_5 } from './erica/erica_5.jpg';
export { default as erica_6 } from './erica/erica_6.jpg';
export { default as erica_7 } from './erica/erica_7.jpg';
export { default as erica_8 } from './erica/erica_8.jpg';
export { default as erica_9 } from './erica/erica_9.jpg';
export { default as erica_10 } from './erica/erica_10.jpg';
export { default as erica_11 } from './erica/erica_11.jpg';

/// MENLO OAKS 3 /////
export { default as menlo_oaks_3_1 } from './menlo_oaks_3/menlo_oaks_3_1.jpg';
export { default as menlo_oaks_3_2 } from './menlo_oaks_3/menlo_oaks_3_2.jpg';
export { default as menlo_oaks_3_3 } from './menlo_oaks_3/menlo_oaks_3_3.jpg';
export { default as menlo_oaks_3_4 } from './menlo_oaks_3/menlo_oaks_3_4.jpg';
export { default as menlo_oaks_3_5 } from './menlo_oaks_3/menlo_oaks_3_5.jpg';
export { default as menlo_oaks_3_6 } from './menlo_oaks_3/menlo_oaks_3_6.jpg';
export { default as menlo_oaks_3_7 } from './menlo_oaks_3/menlo_oaks_3_7.jpg';
export { default as menlo_oaks_3_8 } from './menlo_oaks_3/menlo_oaks_3_8.jpg';
export { default as menlo_oaks_3_9 } from './menlo_oaks_3/menlo_oaks_3_9.jpg';
export { default as menlo_oaks_3_10 } from './menlo_oaks_3/menlo_oaks_3_10.jpg';
export { default as menlo_oaks_3_11 } from './menlo_oaks_3/menlo_oaks_3_11.jpg';
export { default as menlo_oaks_3_12 } from './menlo_oaks_3/menlo_oaks_3_12.jpg';
export { default as menlo_oaks_3_13 } from './menlo_oaks_3/menlo_oaks_3_13.jpg';
export { default as menlo_oaks_3_14 } from './menlo_oaks_3/menlo_oaks_3_14.jpg';
export { default as menlo_oaks_3_15 } from './menlo_oaks_3/menlo_oaks_3_15.jpg';
export { default as menlo_oaks_3_16 } from './menlo_oaks_3/menlo_oaks_3_16.jpg';

////MENLO OAKS 2/////
export { default as menlo_oaks_2_1 } from './menlo_oaks_2/menlo_oaks_2_1.jpg';
export { default as menlo_oaks_2_2 } from './menlo_oaks_2/menlo_oaks_2_2.jpg';
export { default as menlo_oaks_2_3 } from './menlo_oaks_2/menlo_oaks_2_3.jpg';
export { default as menlo_oaks_2_4 } from './menlo_oaks_2/menlo_oaks_2_4.jpg';
export { default as menlo_oaks_2_5 } from './menlo_oaks_2/menlo_oaks_2_5.jpg';
export { default as menlo_oaks_2_6 } from './menlo_oaks_2/menlo_oaks_2_6.jpg';
export { default as menlo_oaks_2_7 } from './menlo_oaks_2/menlo_oaks_2_7.jpg';

////MENLO OAKS 1/////
export { default as menlo_oaks_1_1 } from './menlo_oaks_1/menlo_oaks_1_1.jpg';
export { default as menlo_oaks_1_2 } from './menlo_oaks_1/menlo_oaks_1_2.jpg';
export { default as menlo_oaks_1_3 } from './menlo_oaks_1/menlo_oaks_1_3.jpg';
export { default as menlo_oaks_1_4 } from './menlo_oaks_1/menlo_oaks_1_4.jpg';
export { default as menlo_oaks_1_5 } from './menlo_oaks_1/menlo_oaks_1_5.jpg';
export { default as menlo_oaks_1_6 } from './menlo_oaks_1/menlo_oaks_1_6.jpg';
export { default as menlo_oaks_1_7 } from './menlo_oaks_1/menlo_oaks_1_7.jpg';
export { default as menlo_oaks_1_8 } from './menlo_oaks_1/menlo_oaks_1_8.jpg';
export { default as menlo_oaks_1_9 } from './menlo_oaks_1/menlo_oaks_1_9.jpg';
export { default as menlo_oaks_1_10 } from './menlo_oaks_1/menlo_oaks_1_10.jpg';

////OAKHURST/////
export { default as oakhurst_1 } from './oakhurst/oakhurst_1.jpg';
export { default as oakhurst_2 } from './oakhurst/oakhurst_2.jpg';
export { default as oakhurst_3 } from './oakhurst/oakhurst_3.jpg';
export { default as oakhurst_4 } from './oakhurst/oakhurst_4.jpg';
export { default as oakhurst_5 } from './oakhurst/oakhurst_5.jpg';
export { default as oakhurst_6 } from './oakhurst/oakhurst_6.jpg';
export { default as oakhurst_7 } from './oakhurst/oakhurst_7.jpg';
export { default as oakhurst_8 } from './oakhurst/oakhurst_8.jpg';
export { default as oakhurst_9 } from './oakhurst/oakhurst_9.jpg';
export { default as oakhurst_10 } from './oakhurst/oakhurst_10.jpg';

//PEOPLE//////
export { default as ana_williamson } from './people/ana_williamson.jpg';
export { default as tong_zou } from './people/tong_zou.jpg';
export { default as joe_gardella } from './people/joe_gardella.jpg';
export { default as luna } from './people/luna.jpg';
export { default as ben_flatau } from './people/ben_flatau.jpg';
export { default as alexander_lopez } from './people/alexander_lopez.jpg';
export { default as tom_vogel } from './people/tom_vogel.jpg';
export { default as mania_roumpani } from './people/mania_roumpani.jpg';
export { default as iva_monterrubio_langrova } from './people/iva_monterrubio_langrova.jpg';
export { default as teodora_velkova } from './people/teodora_velkova.jpg';

export { default as studio_1 } from './people/studio_1.jpg';
export { default as studio_2 } from './people/studio_2.jpg';
export { default as studio_3 } from './people/studio_3.jpg';




//NEWS////

export { default as aia_honor_cloud } from './news/aia_honor_cloud.jpg';
export { default as atomic_ranch } from './news/atomic_ranch.jpg';
export { default as design } from './news/design.jpg';
export { default as in_menlo } from './news/in_menlo.jpg';
export {
  default as modern_architect_podcast,
} from './news/modern_architect_podcast.jpg';
export { default as palo_alto_weekly } from './news/palo_alto_weekly.jpg';
export { default as punch_magazine } from './news/punch_magazine.jpg';
export { default as sfgp_1 } from './news/sfgp_1.jpg';
export { default as sfgp_2 } from './news/sfgp_2.jpg';
export { default as sunset } from './news/sunset.jpg';

//FLAG//
export { default as columbian_flag } from './flag/colombia_flag.svg';
