import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ScrollCarouselProject from '../../components/ScrollCarouselProject';
import ScrollToTop from '../../components/ScrollToTop';
import { Helmet } from 'react-helmet';
import { sideNavItemSelected } from '../../actionCreators';
import { loadedApplication } from '../../actionCreators';
import { bindActionCreators } from 'redux';



function select(state) {
  return {
      appData: state.app,
  };
}

class WorkViewContainer extends Component {
  render() {
      const { dispatch } = this.props;
      console.log(this.props, 'work container props')
      return (
        <div className="work-layout-container">
          <ScrollToTop />
          <Helmet>
            <title>Portfolio </title>
            <meta
              name="Portfolio"
              content="AWA is an award winning firm specializing in custom residential projects."
            />
          </Helmet>
          <ScrollCarouselProject
            history={history}
            {...bindActionCreators({ loadedApplication }, dispatch)}
            {...bindActionCreators({ sideNavItemSelected }, dispatch)}
            projectData={this.props.appData.projectData}
            route={this.props.route}
          />
      </div>
      );
  }
}

export default withRouter(connect(select)(WorkViewContainer));




/// OLD FUNCTIONAL THING I DON'T UNDERSTAND ///

// const WorkViewContainer = ({ route, history, portfolioSideNav, projectData }) => (
//   <div className="work-layout-container">
//     <ScrollToTop />
//     <Helmet>
//       <title>Portfolio </title>
//       <meta
//         name="Portfolio"
//         content="AWA is an award winning firm specializing in custom residential projects."
//       />
//     </Helmet>
//     <ScrollCarouselProject
//       history={history}
//       route={route}
//       sideNavItemSelected={sideNavItemSelected}
//       loadedApplication={loadedApplication}
//       projectData={projectData}
//       {...portfolioSideNav}
//     />
//   </div>
// );


// const mapStateToProps = ({
//   modal,
//   portfolioSideNav: { selectedPortfolioSideNavItem },
//   portfolioSideNav,
//   app:{projectData}
// }) => ({
//   modal,
//   selectedPortfolioSideNavItem,
//   portfolioSideNav,
//   projectData
// });

// export default withRouter(connect(mapStateToProps)(WorkViewContainer));
