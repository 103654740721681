import {
  APPLICATION_LOADED,
  CLOSE_MODAL,
  MENU_ITEM_SELECTED,
  OPEN_MODAL,
  PEOPLE_SUB_SIDE_NAV_SELECTED,
  PORTFOLIO_SIDE_NAV_ITEM_SELECTED,
  SIDE_NAV_SELECTED,
  SUBMIT_FORM,
  SUBMIT_CLEARED,
  SUBMIT_MESSAGE
} from '../constants/actions';

export const sideNavItemSelected = itemName => ({
  type: SIDE_NAV_SELECTED,
  data: {
    itemName,
  },
});


const sendApiData = (APIdata)=>{
  return dispatch => {
    dispatch({
        type: APPLICATION_LOADED,
        data: APIdata
    });
  } 
}

export const loadedApplication = () => {
  console.log('loaded application call')
  return (dispatch) => {
  return fetchApiData().then(([response, json]) =>{
    console.log('loaded application function', dispatch)

    dispatch(sendApiData(json))
  })
}
}

function fetchApiData() {
  console.log('fetching api data')
  const airtable_URL = 'https://api.airtable.com/v0/appmtLQ8gvFNNMpsc/Table%201?maxRecords=20&view=Grid%20view'
  return fetch(airtable_URL, { method: 'GET', headers:{'Authorization': 'Bearer patrqSJ11y5zPINxx.adc93675cae2dbf9f440d3179af3c8d56067b1713b4d18bf5cfe70ca4d36ee33'}})
     .then( response => Promise.all([response, response.json()]));
}



export const menuItemSelected = itemName => ({
  type: MENU_ITEM_SELECTED,
  data: {
    itemName,
  },
});

export const openModal = data => ({
  type: OPEN_MODAL,
  modalData: data,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const peopleSubSideNavItemSelected = person => ({
  type: PEOPLE_SUB_SIDE_NAV_SELECTED,
  data: {
    person,
  },
});

export const portfolioSideNavItemSelected = project => ({
  type: PORTFOLIO_SIDE_NAV_ITEM_SELECTED,
  data: {
    project,
  },
});


export function submitCleared() {

  return dispatch => {
      dispatch({
          type: SUBMIT_CLEARED,
      });
      dispatch(submitMessage(null));
  }
}

export const submitMessage= (message) => ({
  type: SUBMIT_MESSAGE,
  data: { message }
});

export function submitForm(emailAddress) {

  return dispatch => {
      dispatch({
          type: SUBMIT_FORM,
      });
      
      dispatch(submitMessage(true));

      fetch('https://arch-folio.com/api/email', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: emailAddress, type: 'process',})
        }).then(res=>res.json())
        .then(res => console.log(res))
  }
}