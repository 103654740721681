import createReducer from '../utils/createReducer';
import { PORTFOLIO_SIDE_NAV_ITEM_SELECTED } from '../constants/actions';
import { awaProjects } from '../constants/appData/projects';
import { get } from 'underscore';

const sideNavProjects = [{ text: 'HOME', redirectURL: 'Home' }];


// // const airtable_API_KEY = 'keygdNmnTD2ChN9od';
// // const airtable_table = 'projects';


// const airtable_URL = 'https://api.airtable.com/v0/appR4w5vy7oRzq7Aa/tblejhU4BvtTelmv4?api_key=keygdNmnTD2ChN9od&sort%5B0%5D%5Bfield%5D=id&sort%5B0%5D%5Bdirection%5D=asc';

// fetch(airtable_URL)
// .then((resp) => resp.json())
// .then(data => {       
//   data.records.forEach(project => {
    
//     sideNavProjects.push({
//       redirectURL: project.fields.displayName,
//       text: project.fields.name
//     })
    
//   })        
// }).catch(err => {
//   console.log('error!:', err) 
// });



const initialState = {
  sideNavItems: sideNavProjects,
  // sideNavItems: getProjects(),
  selectedPortfolioSideNavItem: { text: 'Home', redirectURL: 'Home' },
};

export default createReducer(initialState, {
  [PORTFOLIO_SIDE_NAV_ITEM_SELECTED]: (
    state,
    { data: { project: selectedPortfolioSideNavItem } }
  ) => ({
    ...state,
    selectedPortfolioSideNavItem,
  }),
});



