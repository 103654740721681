import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import './styles.scss';
import { NavLink } from 'react-router-dom';

export default class PortfolioSideNav extends Component {
  render() {
    const { sideNavItems, projectData } = this.props;

    return (
      <MediaQuery minWidth={1025}>
        <div className="portfolio-side-nav-menu">

          {projectData.map(({ fields }, id) => (
            <div key={`${id}-A`} className={'portfolio-side-nav-menu__item-container'}>

              <NavLink
                activeClassName="portfolio-side-nav-menu__item--active"
                className="portfolio-side-nav-menu__item"
                exact
                key={fields.name}
                onClick={() => {
                  const node = document.getElementById(fields.displayName);
                  if (node)
                    fields.displayName !== 'Home' ///BEN CHECK THIS
                    ? node.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    })
                    : node.scrollIntoView();
                       
                }}
                to={
                  fields.name === 'Home' ? '/portfolio' : `/portfolio/${fields.displayName}` /// BEN ALSO CHECK THIS
                }
              >
                {fields.name}
              </NavLink>

              {id === 0 ? (
                <div
                  key={`${id}-B`}
                  className="portfolio-side-nav-sub-header"
                />
              ) : null}
            </div>
          ))}
        </div>
      </MediaQuery>
    );
  }
}
